<template>
<div>
  <!--The button-->
  <div v-on:click="toggle" :class="'relative z-40 '+ icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" viewBox="0 0 25 20">
      <g id="Group_552" data-name="Group 552">
        <line id="top-line" data-name="Line 145" x2="25" transform="translate(0 1)" fill="none" stroke="#fff" stroke-width="2" />
        <g transform="translate(12.5 8.75)">
          <line id="middle-top" data-name="Line 146" x1="-12.5" x2="12.5" fill="none" stroke="#fff" stroke-width="2" />
          <line id="middle-bottom" data-name="Line 146" x1="-12.5" x2="12.5" fill="none" stroke="#fff" stroke-width="2" />
        </g>
        <line id="bottom-line" data-name="Line 147" x2="25" transform="translate(0 16.5)" fill="none" stroke="#fff" stroke-width="2" />
      </g>
    </svg>
  </div>
  <!--The actual menu-->
  <div :class="drawbar">
    <div class="container mx-auto my-6 header-menu inner">
      <div v-if="accounts" class="flex flex-wrap mobile-main-btns mb-6 sm:my-6 sm:hidden">
        <a href="/login" class="w-full">
          <div class="mob_link block my-2 font-semibold">
            {{ text.signup }}
          </div>
        </a>
      </div>
      <div class="flex pb-6">
        <div class="flex-grow ">
          <a href="/">
            <div class="w-1/2 pl-6 mt-8 mb-12 font-sans lg:w-1/5 mb-4 text-grey-darker text-white">
              <h5 class="border-white border border-solid text-center rounded py-2 px-4 hidden md:inline">{{ text.home }}</h5>
            </div>
          </a>
          <div class="flex flex-wrap font-sans text-white sm:pl-6">
            <div v-for="node in footerNodes" class="w-1/2 lg:w-1/5 mb-4">
              <h5 class="text-grey-darker font-bold text-white">
                {{ node.title }}
              </h5>
              <ul class="pr-4">
                <li v-for="subNode in node.subNodes" class="block">
                  <a :href="subNode.url">{{ subNode.title }}</a>
                </li>
              </ul>
            </div>
            <div class="w-1/2 lg:w-1/5 mb-4">
                <slot></slot>
              <!--<h5 class="text-grey-darker font-bold text-white">
                Contact
              </h5>
              <div class="text-white pb-2 block-contact">
                <p>
                  (03) 9723 9989<br>
                  PO Box 980,<br>
                  Croydon VIC 3136, Australia<br>
                </p>
              </div>

              <a class="btn_link text-white" href="mailto:partnership@example.com">Contact Us</a>-->
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    mainNodes: Array,
    footerNodes: Array,
    accounts: Boolean,
    text: Object,
  },
  data() {
    return {
      showMenu: false,
    }
  },
  computed: {
    drawbar() {
      if (this.showMenu) {
        return 'flex items-end sm:items-center absolute z-30 top-0 left-0 right-0 full-menu bg-gray-300';
      } else {
        return 'flex items-end sm:items-center absolute z-30 top-0 left-0 right-0 full-menu bg-gray-300 header-menu-hide';
      }
    },
    icon() {
      if (this.showMenu) {
        return 'show-menu';
      } else {
        return 'hide-menu';
      }
    }
  },
  methods: {
    show() {
      //console.log("Clicking the show button")
      this.showMenu = true
    },
    hide() {
      this.showMenu = false
    },
    toggle() {
      this.showMenu = !this.showMenu
    }
  }
}
</script>
